import fetcher from './fetcher';
export async function createCheckoutSessionForOtherMethods({ booking }) {
    return await fetcher({
        url: '/api/v3/stripe/create-checkout-session-for-other-methods',
        method: 'POST',
        data: {
            bookingId: booking._id
        }
    });
}
export async function createCheckoutSessionForPromotedMethod({ booking, method }) {
    return await fetcher({
        url: `/api/v3/stripe/create-checkout-session-for-promoted-method?method=${method}`,
        method: 'POST',
        data: {
            bookingId: booking._id
        }
    });
}
