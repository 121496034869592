import axios from 'axios';
axios.defaults.baseURL = process.env.API_URL;
export async function fetcher({ url, data, method, baseURL }) {
    const config = {
        url,
        method,
        data,
    };
    if (baseURL) {
        axios.defaults.baseURL = baseURL;
    }
    const response = await axios(config);
    axios.defaults.baseURL = process.env.API_URL;
    return response.data;
}
export default fetcher;
