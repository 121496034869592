import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { useState } from 'react';
import { useI18n } from '../../contexts/i18nContext';
import Drawer from '../tailwind/Drawer';
import Resume from '../Resume/Resume';
export default function FinishBookingPreConfirmation({ children }) {
    const { t } = useI18n();
    const [isOpen, setIsOpen] = useState(false);
    const [readTerms, setReadTerms] = useState(false);
    return (_jsxs(_Fragment, { children: [_jsxs("div", { children: [_jsx("div", { className: "flex justify-end md:hidden", children: _jsxs("button", { className: "text-sm font-bold underline flex items-center my-2", onClick: () => setIsOpen(true), children: [t('showPricingDetails'), _jsx(ChevronDownIcon, { style: { width: 20 } })] }) }), _jsxs("div", { className: "flex gap-2 items-center p-3", children: [_jsx("input", { type: "checkbox", id: "terms", className: "w-4 h-4", checked: readTerms, onChange: () => setReadTerms((prev) => !prev) }), _jsxs("label", { className: " text-sm font-normal text-gray-900", htmlFor: "terms", children: [t('readAndAccept'), ' ', _jsx("a", { className: "text-main", href: "https://www.explore-share.com/terms/", target: "_blank", children: t('termsAndConditions') })] })] }), children(readTerms)] }), _jsx(Drawer, { isOpen: isOpen, setIsOpen: setIsOpen, children: _jsx(Resume, {}) })] }));
}
