import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useI18n } from '../../contexts/i18nContext';
export default function Drawer({ children, isOpen, setIsOpen }) {
    const { t } = useI18n();
    return (_jsxs("main", { className: ' fixed overflow-hidden z-10 bg-gray-900 bg-opacity-25 inset-0 transform ease-in-out ' +
            (isOpen
                ? ' transition-opacity opacity-100 duration-500 translate-y-0  '
                : ' transition-all delay-500 opacity-0 translate-y-full  '), children: [_jsx("section", { className: 'bottom-0 w-full  max-h-lg right-0 absolute bg-white shadow-xl delay-400 duration-500 ease-in-out transition-all transform  ' +
                    (isOpen ? ' translate-y-0 ' : ' translate-y-full '), children: _jsxs("article", { className: "mt-1 relative w-full flex flex-col overflow-y-scroll h-full", children: [_jsx("button", { className: "py-2 px-4 font-bold text-sm underline text-end", onClick: () => {
                                setIsOpen(false);
                            }, children: t('closeDetails') }), children] }) }), _jsx("section", { className: "h-1/2 cursor-pointer ", onClick: () => {
                    setIsOpen(false);
                } })] }));
}
