import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { usePayment } from '../../contexts/PaymentContext';
import { MobileResume } from '../Resume/MobileResume';
import PaymentMethods from './PaymentMethods';
import PaymentModalities from './PaymentModalities';
const stripePromise = loadStripe(process.env.STRIPE_KEY);
export default function Payments() {
    const { paymentModality } = usePayment();
    const paymentModalityChosen = Boolean(paymentModality);
    return (_jsxs(Elements, { stripe: stripePromise, children: [_jsx(PaymentModalities, {}), _jsx(MobileResume, {}), paymentModalityChosen && _jsx(PaymentMethods, {})] }));
}
