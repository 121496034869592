import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useEffect, useState } from 'react';
import rosetta from 'rosetta';
import frTable from '../lib/locale/fr';
import enTable from '../lib/locale/en';
import esTable from '../lib/locale/es';
import { useBooking } from './BookingContext';
const i18n = rosetta();
const DEFAULT_LOCALE = 'en';
export const I18nContext = createContext({
    t(key, data) {
        return '';
    },
    currentLocale: DEFAULT_LOCALE
});
const tables = {
    fr: frTable,
    en: enTable,
    es: esTable
};
const availableLocales = Object.keys(tables);
export default function I18nContextProvider({ children }) {
    const { booking } = useBooking();
    const [locale, setLocale] = useState(DEFAULT_LOCALE);
    useEffect(() => {
        if (booking) {
            const leadLocale = booking.lead.preferences.locale;
            if (!availableLocales.includes(leadLocale))
                return;
            if (leadLocale === locale)
                return;
            setLocale(leadLocale);
        }
    }, [booking?.lead]);
    const table = tables[locale];
    i18n.locale(locale);
    i18n.set(locale, table);
    const i18nWrapper = {
        currentLocale: locale,
        t(key, data) {
            const trans = i18n.t(key, data, locale);
            return trans || key;
        },
        table: () => table,
        locale: (l) => i18n.locale(l)
    };
    return _jsx(I18nContext.Provider, { value: i18nWrapper, children: children });
}
export function useI18n() {
    return useContext(I18nContext);
}
