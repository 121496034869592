import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import dayjs from 'dayjs';
import { useEffect, useReducer, useState } from 'react';
import * as Sentry from '@sentry/react';
import { toast } from 'react-hot-toast';
import saveLeadBillingDetails from '../../api-requests/billing';
import fetcher from '../../api-requests/fetcher';
import { updateLeadData } from '../../api-requests/lead';
import { useBooking } from '../../contexts/BookingContext';
import { useI18n } from '../../contexts/i18nContext';
import CustomCombobox from '../tailwind/Combobox';
import Input from '../tailwind/Input';
const toastOptions = {
    position: 'bottom-center',
    style: {
        width: '250px',
        fontSize: '16px',
        fontFamily: 'Open Sans',
        height: '40px',
    },
    success: {
        duration: 3000,
    },
    error: {
        duration: 3000,
    },
};
function useFetchCountries() {
    const [countries, setCountries] = useState([]);
    useEffect(() => {
        fetcher({
            url: '/api/v1/dashboard/places/country',
            method: 'GET',
        }).then(setCountries);
    }, []);
    return countries;
}
function useFetchBillingLead() {
    const { booking } = useBooking();
    const [lead, setLead] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        if (booking) {
            fetcher({
                url: `v1/leads/${booking.lead._id}`,
                method: 'GET',
                baseURL: process.env.API_BILLING_URL,
            })
                .then(setLead)
                .finally(() => setLoading(false));
        }
    }, [booking]);
    return {
        lead,
        loadingLead: loading,
    };
}
function clearEmptyKeys(object) {
    const newObject = {};
    Object.keys(object).forEach(key => {
        if (object[key]) {
            newObject[key] = object[key];
        }
    });
    return newObject;
}
const SET_BILLING_DETAILS = 'SET_BILLING_DETAILS';
const initialState = {
    billing_address: '',
    billing_address_number: '',
    billing_zip: '',
    billing_city: '',
    billing_country: '',
    first_name: '',
    last_name: '',
};
function billingDetailsReducer(state, action) {
    switch (action.type) {
        case SET_BILLING_DETAILS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
}
export default function BillingDetails({ children, }) {
    const { booking } = useBooking();
    const { t } = useI18n();
    const countries = useFetchCountries();
    const { lead: billingLead, loadingLead } = useFetchBillingLead();
    const [billingDetailsCompleted, setBillingDetailsCompleted] = useState(false);
    const [billingDetails, dispatch] = useReducer(billingDetailsReducer, initialState);
    const [birthdate, setBirthdate] = useState('');
    const handleSaveBillingDetails = async () => {
        if (!booking)
            return;
        await toast
            .promise(saveLeadBillingDetails({
            leadId: booking.lead._id,
            data: clearEmptyKeys(billingDetails),
        }), {
            loading: t('thankYouPage.savingBillingDetails'),
            success: t('thankYouPage.savingBillingDetailsSuccess'),
            error: t('thankYouPage.savingBillingDetailsError'),
        }, toastOptions)
            .catch(err => {
            Sentry.captureException(`error saving billing details ${booking._id} - ${err.toString()}`, {
                data: JSON.stringify(billingDetails),
            });
        });
        await updateLeadData({
            leadId: booking.lead._id,
            data: {
                birthdate,
            },
        });
        setBillingDetailsCompleted(true);
    };
    useEffect(() => {
        if (loadingLead || !booking)
            return;
        if (!billingLead) {
            fetch('https://geolocation-db.com/json/', {
                method: 'GET',
            })
                .then(geo => geo.json())
                .then((geoData) => {
                const payload = {
                    billing_country: geoData.country_name,
                    first_name: booking.lead.first_name,
                    last_name: booking.lead.first_name,
                };
                dispatch({
                    type: SET_BILLING_DETAILS,
                    payload,
                });
                saveLeadBillingDetails({
                    leadId: booking.lead._id,
                    data: payload,
                });
            })
                .catch(() => {
                const billing_country = booking.geo?.country_name;
                if (!billing_country)
                    return;
                dispatch({
                    type: SET_BILLING_DETAILS,
                    payload: {
                        billing_country,
                    },
                });
                saveLeadBillingDetails({
                    leadId: booking.lead._id,
                    data: {
                        billing_country,
                    },
                });
            });
        }
        else {
            dispatch({
                type: SET_BILLING_DETAILS,
                payload: {
                    billing_address: billingLead.address,
                    billing_address_number: billingLead.address_number,
                    billing_zip: billingLead.zip_code,
                    billing_city: billingLead.city,
                    billing_country: billingLead.country,
                    first_name: billingLead.first_name,
                    last_name: billingLead.last_name,
                },
            });
        }
    }, [loadingLead, billingLead, booking]);
    const setBillingDetails = (key) => {
        return (payload) => {
            dispatch({
                type: SET_BILLING_DETAILS,
                payload: {
                    [key]: payload,
                },
            });
        };
    };
    const formCompleted = Boolean(billingDetails.billing_country);
    if (billingDetailsCompleted)
        return children({
            completed: true,
            component: (_jsx("div", { children: _jsx("h3", { className: "mt-10 mb-6 text-lg font-bold", children: t('thankYouPage.thankYouBilling') }) })),
        });
    return children({
        completed: false,
        component: (_jsxs("div", { children: [_jsx("h3", { className: "mt-10 mb-6 font-bold text-lg", children: t('thankYouPage.completeBillingDetails') }), _jsxs("div", { className: "block md:flex gap-2 items-center", children: [_jsxs("div", { className: "w-full md:w-3/4 block md:flex gap-2 items-center", children: [_jsx("div", { className: "w-full md:w-1/2", children: _jsx(Input, { avoidErrors: true, label: t('firstName'), placeholder: "John", onChange: setBillingDetails('first_name'), value: billingDetails.first_name, isValid: billingDetails.first_name?.length > 0 }) }), _jsx("div", { className: "w-full md:w-1/2", children: _jsx(Input, { avoidErrors: true, label: t('lastName'), placeholder: "Doe", onChange: setBillingDetails('last_name'), value: billingDetails.last_name, isValid: billingDetails.last_name?.length > 0 }) })] }), _jsx("div", { className: "w-full md:w-1/4", children: _jsxs("div", { className: "mb-2", children: [_jsx("label", { className: "block text-sm", children: "Birthdate" }), _jsx("div", { children: _jsx("input", { defaultValue: booking?.lead.birthdate
                                                ? dayjs
                                                    .utc(booking.lead.birthdate)
                                                    .format('YYYY-MM-DD')
                                                : undefined, type: "date", id: "birthdate", name: "birthdate", className: "w-full text-sm p-2 border border-gray-200 rounded", onChange: e => {
                                                setBirthdate(e.target.value);
                                            } }) })] }) })] }), _jsxs("div", { className: "block md:flex gap-2", children: [_jsx("div", { className: "w-full md:w-3/4", children: _jsx(Input, { avoidErrors: true, label: t('address'), placeholder: "Main Street", onChange: setBillingDetails('billing_address'), value: billingDetails.billing_address, isValid: billingDetails.billing_address?.length > 0 }) }), _jsx("div", { className: "w-full md:w-1/4", children: _jsx(Input, { avoidErrors: true, label: t('addressNumber'), placeholder: "123", onChange: setBillingDetails('billing_address_number'), value: billingDetails.billing_address_number, isValid: billingDetails.billing_address_number?.length >
                                    0 }) })] }), _jsxs("div", { className: "block md:flex gap-2", children: [_jsx("div", { className: "w-full md:w-3/4", children: _jsx(Input, { label: t('city'), placeholder: "New York", avoidErrors: true, onChange: setBillingDetails('billing_city'), value: billingDetails.billing_city, isValid: billingDetails.billing_city?.length > 0 }) }), _jsx("div", { className: "w-full md:w-1/4", children: _jsx(Input, { label: t('zipCode'), avoidErrors: true, placeholder: "10001", isValid: billingDetails.billing_zip?.length > 0, onChange: setBillingDetails('billing_zip'), value: billingDetails.billing_zip }) })] }), _jsxs("label", { className: "text-sm text-gray-900", children: [t('country'), _jsx(CustomCombobox, { handleOnChange: country => setBillingDetails('billing_country')(country.title), options: countries, defaultValue: billingDetails.billing_country })] }), _jsx("div", { className: "flex justify-end mt-2", children: _jsx("button", { disabled: !formCompleted, className: `${formCompleted ? 'primary-button' : 'disabled-primary-button'} text-sm`, onClick: handleSaveBillingDetails, children: t('thankYouPage.saveBillingDetails') }) })] })),
    });
}
