import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useEffect, useState } from 'react';
import fetchCurrencyRates from '../api-requests/rates';
const RatesContext = createContext(null);
export function useRates() {
    return useContext(RatesContext);
}
function useFetchRates() {
    const [rates, setRates] = useState(null);
    useEffect(() => {
        fetchCurrencyRates().then(setRates);
    }, []);
    return rates;
}
export default function RatesProvider({ children }) {
    const rates = useFetchRates();
    return _jsx(RatesContext.Provider, { value: rates, children: children });
}
