import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createRef, useEffect } from 'react';
import { useBooking } from '../../../contexts/BookingContext';
import { useI18n } from '../../../contexts/i18nContext';
import { usePayment } from '../../../contexts/PaymentContext';
import formatMoney from '../../../lib/formatMoney';
import { PAYMENT_METHODS } from '../../../lib/payments/constant';
import CheckBox from '../CheckBox';
import FinishBooking from '../FinishBooking';
import Resume from '../../Resume/Resume';
export default function BankTransfer() {
    const wireRef = createRef();
    const { booking } = useBooking();
    const { t } = useI18n();
    const { setPaymentMethod, paymentMethod, paymentModality, isUpfrontPayment, isBankTransfer, basePriceWithCustomerFee, loadingPrices, depositWithCard, } = usePayment();
    useEffect(() => {
        if (isBankTransfer && wireRef.current) {
            wireRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [isBankTransfer, wireRef]);
    if (!booking)
        return null;
    return (_jsxs("div", { ref: wireRef, children: [_jsx(CheckBox, { label: _jsxs("div", { className: "w-full flex items-center gap-1", children: [t('bankTransfer'), ' ', loadingPrices ? (_jsx("div", { className: "h-3 mt-1 bg-gray-200 rounded-full dark:bg-gray-200 w-1/6" })) : (_jsxs("span", { className: "text-sm font-extralight", children: ["(+", booking.leadCurrency, " 0.00)"] }))] }), isActive: isBankTransfer, setPaymentModality: () => {
                    if (paymentMethod === PAYMENT_METHODS.WIRE)
                        return setPaymentMethod(null);
                    return setPaymentMethod(PAYMENT_METHODS.WIRE);
                }, children: isUpfrontPayment ? (_jsx(_Fragment, { children: t('weWillProvideInstructions') })) : (_jsxs("p", { children: [t('youWillPayATotal'), ' ', loadingPrices ? (_jsxs("span", { className: "inline-flex", children: [_jsx("div", { className: "h-3 mt-1 bg-gray-200 rounded-full dark:bg-gray-200 w-[100px]" }), "."] })) : (_jsxs("span", { className: "font-bold", children: [booking.leadCurrency, " ", formatMoney(basePriceWithCustomerFee, booking.leadCurrency)] })), ' ', t('instructionsWillBeSentByMail', { email: booking.lead.email })] })) }), isBankTransfer && Boolean(paymentModality) ? (_jsx(FinishBooking, { isValidForm: true, children: _jsx(Resume, {}) })) : null] }));
}
