import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import Resume from '../../components/Resume/Resume';
import Payments from '../../components/Payments';
import { useBooking } from '../../contexts/BookingContext';
import Logo from '../../components/Logo';
import Amplitude from '../../lib/Amplitude';
import PageType from '../../constants/PageType';
dayjs.extend(utc);
export default function Main() {
    const navigate = useNavigate();
    const { booking } = useBooking();
    const isPaid = booking?.state === 'B:PAID';
    const hasPendingPayment = booking?.payments?.length
        ? booking?.payments.some(payment => ['pending', 'none'].includes(payment.status))
        : false;
    useEffect(() => {
        if (!booking)
            return;
        if (isPaid || hasPendingPayment) {
            navigate({
                pathname: '/thank-you',
                search: window.location.search,
            });
        }
    });
    useEffect(() => {
        const { lead, trip } = booking;
        Amplitude.pageViewed({
            page_type: PageType.common,
            product_id: trip._id,
            activities: trip.activities?.map((activity) => activity.i18n.en_EN.title),
            country: trip.countries[0]?.i18n.en_EN.name,
            region: booking?.trip.regions?.map(v => v.i18n.en_EN.title),
            location: booking?.trip.locations?.map(v => v.i18n.en_EN.title),
        });
        Amplitude?.setUserId(lead._id);
        Amplitude?.setUser({
            user_id: lead._id,
            created: lead.created,
            email: lead.email,
            first_name: lead.first_name,
            last_name: lead.last_name,
            phone: lead.phone,
            preferences: lead.preferences,
        });
    }, []);
    if (!booking || isPaid || hasPendingPayment)
        return null;
    return (_jsxs("div", { className: "mb-6", children: [_jsx(Logo, {}), _jsxs("div", { className: "flex gap-4 justify-center px-4 w-full lg:w-10/12 xl:w-8/12 2xl:w-6/12 mx-auto", children: [_jsx("div", { className: "w-full md:w-3/5 mb-0 md:mb-16", children: _jsx(Payments, {}) }), _jsx("div", { className: "w-0 hidden md:w-2/5 md:block", children: _jsx(Resume, {}) })] })] }));
}
