import { jsx as _jsx } from "react/jsx-runtime";
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import App from './App';
import './index.css';
import './dataLayer';
Sentry.init({
    dsn: 'https://24dfd5fd48c04daa7c25256b9fadba0a@o4504248202231808.ingest.us.sentry.io/4507328981172224',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Performance Monitoring
    tracesSampleRate: 1.0,
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/www.explore-share\.com/],
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
const rootNode = document.getElementById('root');
ReactDOM.render(_jsx(App, {}), rootNode);
