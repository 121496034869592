import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ISO_LOCALE } from '../../constants/locale';
import { useBooking } from '../../contexts/BookingContext';
import { useI18n } from '../../contexts/i18nContext';
import usePostPayment from '../../hooks/usePostPayment';
export default function FinishBooking({ isValidForm, checkErrors, paymentData, installmentId, text, children, externalUrl, }) {
    const { t, currentLocale } = useI18n();
    const { isLoading, postPayment, postInstallmentPayment } = usePostPayment(paymentData);
    const { booking } = useBooking();
    const disabledButton = !isValidForm || isLoading;
    const fn = installmentId ? postInstallmentPayment : postPayment;
    const linkTrip = booking?.trip.i18n[ISO_LOCALE[currentLocale]]?.link;
    return (_jsx(_Fragment, { children: _jsxs("div", { children: [_jsx("div", { className: "flex gap-2 items-center p-1 mt-4", children: _jsxs("label", { className: "text-xs font-normal text-gray-700", htmlFor: "terms", children: ["By continuing, you confirm that you have read and accept the", ' ', _jsx("a", { className: "text-main", href: "https://www.explore-share.com/terms/", target: "_blank", rel: "noreferrer", children: t('termsAndConditions') })] }) }), externalUrl ? (_jsx("a", { className: `${disabledButton ? 'bg-main/30' : 'bg-main hover:shadow-md transition-all'} flex items-center justify-center t-2 text-white py-2 px-4 rounded w-full text-sm mt-1 h-10`, href: externalUrl, children: text ?? t('confirmBooking') })) : (_jsx("button", { onClick: () => {
                        try {
                            checkErrors && checkErrors();
                            fn(installmentId);
                        }
                        catch (error) {
                            console.log(error);
                        }
                    }, className: `${'bg-main hover:shadow-md transition-all'}  text-white py-2 px-4 rounded w-full text-sm mt-1 h-10`, children: text ?? t('confirmBooking') })), linkTrip && (_jsx("div", { className: "text-sm mt-3 md:p-0 pb-4 px-4 text-center md:hidden", children: _jsx("a", { href: `${linkTrip}more-info`, target: "_blank", className: "text-main", rel: "noreferrer", children: t('askQuestions') }) }))] }) }));
}
