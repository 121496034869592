import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Item from './Item';
import { useBooking } from '../../contexts/BookingContext';
import Dates from './Dates';
import { useI18n } from '../../contexts/i18nContext';
import formatMoney from '../../lib/formatMoney';
import { ISO_LOCALE } from '../../constants/locale';
export default function InstallmentResume({ installment, }) {
    const { t, currentLocale } = useI18n();
    const { booking } = useBooking();
    const i18nLocale = ISO_LOCALE[currentLocale];
    if (!booking)
        return null;
    const { leadCurrency, payment_method, payment_options, payment_plan, duration } = booking;
    const ccFee = installment.data.summary.find((fee) => fee.name === 'credit_card_fee');
    const customerFee = installment.data.summary.find((fee) => fee.name === 'customer_fee');
    const subTotalTitle = (() => {
        if (payment_method === 'deposit' && payment_options.payment_method === 'group') {
            if (installment.first) {
                return t('subtotalPerPerson', { percentage: payment_options.deposit_percentage });
            }
            return t('subtotalPerPerson', { percentage: 100 - payment_options.deposit_percentage });
        }
        return t('subTotal');
    })();
    const { quantity } = payment_plan.original;
    const { pricePerPersonWithServiceFee } = payment_plan.computedCalculations;
    const { title } = booking.trip.i18n[i18nLocale];
    return (_jsxs("div", { className: "border rounded sticky top-3 mt-0 md:mt-7", children: [_jsxs("div", { className: "flex items-center px-4 pt-4 gap-2", children: [_jsx("h4", { className: "text-base md:text-lg font-bold", children: title }), _jsx("img", { src: `${booking.trip.cover_url}?w=100&h=100`, className: "block object-cover h-[50px] w-[50px] rounded" })] }), _jsxs("div", { className: "p-4", children: [_jsx(Dates, {}), _jsx(Item, { label: t('tripDays'), value: duration }), _jsx(Item, { label: t('travellers'), value: quantity }), _jsxs("div", { children: [_jsx(Item, { label: `${t('pricePerPerson')}`, value: `${leadCurrency} ${formatMoney(pricePerPersonWithServiceFee, leadCurrency)}` }), _jsx("hr", { className: "h-[1px] w-full bg-gray-500 my-3 " }), _jsx(Item, { label: subTotalTitle, value: `${leadCurrency} ${formatMoney(installment.data.subtotal + (customerFee?.value || 0), leadCurrency)}` }), ccFee ? (_jsx(Item, { label: `+ ${t('creditCardFee')}`, value: `${leadCurrency} ${formatMoney(ccFee.value, leadCurrency)}` })) : null, _jsx("hr", { className: "h-[1px] w-full bg-gray-500 my-3 " }), _jsx(Item, { label: t('totalToPayNow'), value: `${leadCurrency} ${formatMoney(installment.data.total, leadCurrency)}`, important: true })] })] })] }));
}
