import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext } from 'react';
const InstallmentContext = createContext({
    getInstallmentToCharge: () => ({
        installment: null,
        installmentIndex: null,
        paid: false,
        notValid: false,
    }),
});
export function useInstallment() {
    return useContext(InstallmentContext);
}
export default function InstallmentContextProvider({ children }) {
    const getInstallmentToCharge = (booking) => {
        if (!booking || !booking.payment_plan)
            return { installment: null, installmentIndex: null, paid: false };
        const installmentId = new URLSearchParams(window.location.search).get('installmentId') ||
            (() => {
                let installmentToCharge;
                const succeededPayments = booking.payments.filter(payment => ['succeeded', 'requires_action'].includes(payment.status) ||
                    (payment.status === 'pending' && payment.method === 'cc'));
                for (const installment of booking.payment_plan.final) {
                    const paidInstallment = succeededPayments.find(succeededPayment => succeededPayment.installment_id === installment._id);
                    if (paidInstallment)
                        continue;
                    installmentToCharge = installment;
                    break;
                }
                return installmentToCharge?._id;
            })();
        const installment = booking.payment_plan.final.find(payment => {
            return payment._id === installmentId;
        });
        const installmentIndex = booking.payment_plan.final.findIndex(payment => {
            return payment._id === installmentId;
        });
        if (!installment) {
            return { installment: null, installmentIndex: null, paid: false };
        }
        const installmentInPayments = booking.payments.find(payment => payment.installment_id === installmentId);
        if (installmentInPayments?.status === 'succeeded') {
            return { installment: installment, installmentIndex, paid: true };
        }
        if (installment.data.fragment?.method !== 'cc') {
            return { installment: installment, installmentIndex, paid: false, notValid: true };
        }
        return { installment, installmentIndex, paid: false };
    };
    return (_jsx(InstallmentContext.Provider, { value: {
            getInstallmentToCharge,
        }, children: children }));
}
