import { useStripe } from '@stripe/react-stripe-js';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useBooking } from '../contexts/BookingContext';
import { usePayment } from '../contexts/PaymentContext';
import handlePay, { handleWalletPayment } from '../lib/payments/post-payment/handle-pay';
import { useI18n } from '../contexts/i18nContext';
import { useInstallment } from '../contexts/InstallmentContext';
import { persistPriceCalculation } from '../api-requests/booking';
import { DEFAULT_DEPOSIT_PERCENTAGE, PAYMENT_METHODS, PAYMENT_OPTIONS, } from '../lib/payments/constant';
import { getRevenue } from '../lib/bookingUtils';
import { useUser } from '../contexts/UserContext';
import { Analytics } from './usePostPayment_Analytics';
const toastOptions = {
    position: 'bottom-center',
    style: {
        fontSize: '16px',
        fontFamily: 'Open Sans',
        padding: '8 10px',
    },
};
const toastOptionsWithDuration = {
    ...toastOptions,
    duration: 5000,
};
export default function usePostPayment(paymentData) {
    const { t } = useI18n();
    const stripe = useStripe();
    const { booking, refetch } = useBooking();
    const navigate = useNavigate();
    const { isCreditCard, isUpfrontPayment, paymentMethod, isBankTransfer, isInstallments, installmentsPayments, } = usePayment();
    const [isLoading, setIsLoading] = useState(false);
    const { origin } = useUser();
    const redirectToThankYouPage = async () => {
        await refetch();
        navigate({ pathname: '/thank-you', search: window.location.search });
    };
    const { getInstallmentToCharge } = useInstallment();
    const postPayment = async () => {
        const loadingToast = toast.loading(t('processingPayment'), toastOptions);
        setIsLoading(true);
        const method = (() => {
            if (isUpfrontPayment)
                return PAYMENT_METHODS.DEPOSIT;
            if (isBankTransfer)
                return PAYMENT_METHODS.WIRE;
            if (isInstallments)
                return PAYMENT_METHODS.INSTALLMENTS;
            return PAYMENT_METHODS.CREDIT_CARD;
        })();
        const options = () => {
            if (method === PAYMENT_METHODS.DEPOSIT)
                return {
                    deposit_percentage: DEFAULT_DEPOSIT_PERCENTAGE,
                    rest_to_the_guide: false,
                    payment_method: isBankTransfer ? PAYMENT_OPTIONS.WIRE : paymentMethod,
                };
            if (method === PAYMENT_METHODS.INSTALLMENTS)
                return {
                    installment_period: 1,
                    installment_count: installmentsPayments.length,
                    installment_amount: installmentsPayments[0].data.total,
                };
            return {};
        };
        await persistPriceCalculation({
            paymentMethod: method,
            bookingId: booking?._id,
            options: options(),
        });
        const newBooking = await refetch();
        // calculate revenue
        const revenueCalculated = newBooking
            ? await getRevenue(newBooking._id)
            : { value: 0, currency: null };
        const revenue = {
            currency: revenueCalculated.currency,
            value: revenueCalculated.value.toFixed(2),
        };
        try {
            const { installment, installmentIndex } = getInstallmentToCharge(newBooking);
            const hasToPay = isUpfrontPayment ||
                isCreditCard ||
                ['installments', 'group'].includes(newBooking?.payment_method) ||
                installment?.data.fragment?.method === 'cc';
            if (!hasToPay || !paymentData) {
                Analytics(booking, newBooking, installment, revenue, installmentIndex, origin);
                redirectToThankYouPage();
                return Promise.resolve();
            }
            await handlePay({
                paymentData,
                bookingId: newBooking?._id,
                stripe,
                country: newBooking?.geo?.country_name,
                installmentId: installment?._id,
            });
            toast.success(t('succeededPayment'), toastOptionsWithDuration);
            const lastBookingVersion = await refetch();
            Analytics(booking, lastBookingVersion, installment, revenue, installmentIndex, origin);
            redirectToThankYouPage();
        }
        catch (error) {
            toast.error(t(error), toastOptionsWithDuration);
        }
        finally {
            toast.dismiss(loadingToast);
            setIsLoading(false);
        }
    };
    const postInstallmentPayment = async (installmentId) => {
        const loadingToast = toast.loading(t('processingPayment'), toastOptions);
        setIsLoading(true);
        if (!paymentData) {
            toast.dismiss(loadingToast);
            toast.error(t('paymentFailed'), toastOptionsWithDuration);
            setIsLoading(false);
            return;
        }
        const revenue = booking ? await getRevenue(booking._id) : { value: 0, currency: null };
        const { installment, installmentIndex } = getInstallmentToCharge(booking);
        try {
            await handlePay({
                paymentData,
                bookingId: booking?._id,
                stripe,
                country: booking?.geo?.country_name,
                installmentId,
            });
            toast.success(t('succeededPayment'), toastOptionsWithDuration);
            const newBooking = await refetch();
            Analytics(booking, newBooking, installment, revenue, installmentIndex, origin);
            redirectToThankYouPage();
        }
        catch (error) {
            toast.error(t(error), toastOptionsWithDuration);
        }
        finally {
            toast.dismiss(loadingToast);
            setIsLoading(false);
        }
    };
    const postWalletPayment = async (ev) => {
        if (!booking || !stripe)
            return Promise.resolve();
        await persistPriceCalculation({
            paymentMethod: PAYMENT_METHODS.CREDIT_CARD,
            bookingId: booking?._id,
            options: {},
        });
        const newBooking = await refetch();
        const { installment, installmentIndex } = getInstallmentToCharge(newBooking);
        await handleWalletPayment({
            stripe,
            ev,
            booking: newBooking,
        });
        const revenue = newBooking ? await getRevenue(newBooking._id) : { value: 0, currency: null };
        const lastBookingVersion = await refetch();
        Analytics(booking, lastBookingVersion, installment, revenue, installmentIndex, origin);
        redirectToThankYouPage();
    };
    return { postPayment, postInstallmentPayment, postWalletPayment, isLoading };
}
