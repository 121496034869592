import { useEffect, useState } from 'react';
import { createCheckoutSessionForPromotedMethod, createCheckoutSessionForOtherMethods, } from '../api-requests/stripe';
import { useBooking } from '../contexts/BookingContext';
import { usePayment } from '../contexts/PaymentContext';
export default function useRedirectToStripe() {
    const { paymentModality } = usePayment();
    const { booking } = useBooking();
    const [stripeUrl, setStripeUrl] = useState(null);
    const [stripePromotedMethodUrl, setStripePromotedMethodUrl] = useState(undefined);
    const payWithOtherMethods = () => {
        if (booking && stripeUrl) {
            window.location.href = stripeUrl;
            return;
        }
    };
    const payWithPromotedMethod = () => {
        if (booking && stripePromotedMethodUrl) {
            window.location.href = stripePromotedMethodUrl;
            return;
        }
    };
    useEffect(() => {
        if (booking) {
            createCheckoutSessionForOtherMethods({ booking }).then(res => setStripeUrl(res.stripeUrl));
            createCheckoutSessionForPromotedMethod({ booking, method: 'klarna' }).then(res => {
                return setStripePromotedMethodUrl(res.stripeUrl);
            });
        }
    }, [paymentModality, booking]);
    return { stripeUrl, stripePromotedMethodUrl, payWithPromotedMethod, payWithOtherMethods };
}
