import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useBooking } from '../../contexts/BookingContext';
import { useI18n } from '../../contexts/i18nContext';
import { usePayment } from '../../contexts/PaymentContext';
import formatMoney from '../../lib/formatMoney';
import ChangeCurrency from './ChangeCurrency';
import Item from './Item';
export function MobileResume() {
    const { t } = useI18n();
    const { booking } = useBooking();
    const { finalPriceForResume, pricePerPersonTotal, basePriceWithCustomerFee, currentCcFeeInResume, isCreditCard, isUpfrontPayment, isWallet, isInstallments, } = usePayment();
    if (!booking)
        return null;
    const { leadCurrency, people_count } = booking;
    const perPersonText = `${leadCurrency} ${formatMoney(pricePerPersonTotal, leadCurrency)} x ${people_count}`;
    return (_jsxs("div", { className: "block md:hidden", children: [_jsx("h2", { className: "text-lg font-bold mt-7 mb-1", children: "Price details" }), _jsx(Item, { label: `${perPersonText} ${t('travellers')?.toLowerCase()}`, value: `${leadCurrency} ${formatMoney(basePriceWithCustomerFee, leadCurrency)}` }), isCreditCard || isInstallments || isWallet ? (_jsx(Item, { label: `${t('paymentProcessingFee')}`, value: `${leadCurrency} ${formatMoney(currentCcFeeInResume, leadCurrency)}` })) : (_jsx(Item, { label: `${t('paymentProcessingFee')}`, value: `${leadCurrency} 0.00` })), _jsx(Item, { label: t('total'), value: `${leadCurrency} ${formatMoney(finalPriceForResume, leadCurrency)}`, important: true }), _jsx(ChangeCurrency, {})] }));
}
