import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { createRef, useEffect } from 'react';
import dayjs from 'dayjs';
import { useBooking } from '../../../contexts/BookingContext';
import { useI18n } from '../../../contexts/i18nContext';
import { usePayment } from '../../../contexts/PaymentContext';
import { PAYMENT_METHODS } from '../../../lib/payments/constant';
import CheckBox from '../CheckBox';
import FinishBooking from '../FinishBooking';
import formatMoney from '../../../lib/formatMoney';
import Resume from '../../Resume/Resume';
import CreditCardForm from './CreditCardForm';
export default function CreditCard() {
    const ccRef = createRef();
    const { booking } = useBooking();
    const { t } = useI18n();
    const { setPaymentMethod, paymentMethod, paymentModality, isUpfrontPayment, isInstallments, isCreditCard, fullCreditCard, depositWithCard, loadingPrices, installmentsPayments, } = usePayment();
    const { remaining } = depositWithCard;
    const { amount } = remaining;
    useEffect(() => {
        if (isCreditCard && ccRef.current) {
            ccRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [isCreditCard, ccRef]);
    if (!booking)
        return null;
    return (_jsxs("div", { ref: ccRef, children: [_jsx(CheckBox, { label: _jsxs("div", { className: "w-full flex items-center gap-1", children: [t('creditCard'), '', loadingPrices ? (_jsx("div", { className: "h-3 mt-1 bg-gray-200 rounded-full dark:bg-gray-200 w-1/6" })) : (_jsxs("span", { className: "text-sm font-extralight flex items-center", children: ["(+", booking.leadCurrency, " ", formatMoney(fullCreditCard.ccFee, booking.leadCurrency), ")"] }))] }), isActive: isCreditCard, setPaymentModality: () => {
                    if (paymentMethod === PAYMENT_METHODS.CREDIT_CARD)
                        return setPaymentMethod(null);
                    return setPaymentMethod(PAYMENT_METHODS.CREDIT_CARD);
                }, children: isUpfrontPayment ? (_jsxs("div", { children: [loadingPrices ? (_jsx("div", { className: "inline-flex h-3 mt-1 bg-gray-200 rounded-full dark:bg-gray-200 w-1/6" })) : (_jsxs("span", { className: "font-bold", children: [booking.leadCurrency, ' ', formatMoney(depositWithCard.deposit.amount, booking.leadCurrency), ' '] })), _jsxs("span", { children: [" ", t('dueToday'), ", "] }), loadingPrices ? (_jsx("div", { className: "inline-flex h-3 mt-1 bg-gray-200 rounded-full dark:bg-gray-200 w-1/6" })) : (_jsxs("span", { className: "font-bold", children: [booking.leadCurrency, " ", formatMoney(amount, booking.leadCurrency)] })), ' ', _jsx("span", { children: t('charged45DaysBeforeDP') })] })) : (_jsxs(_Fragment, { children: [_jsxs("p", { children: [t('youWillPayATotal'), ' ', loadingPrices ? (_jsx("div", { className: "h-3 mt-1 bg-gray-200 rounded-full dark:bg-gray-200 w-1/6" })) : (_jsxs("strong", { children: [booking.leadCurrency, " ", formatMoney(fullCreditCard.amount, booking.leadCurrency)] })), isInstallments ? (_jsxs(_Fragment, { children: [", ", t('inNInstallmentsOf', { installments: installmentsPayments.length }), ": "] })) : null] }), isInstallments && installmentsPayments.length ? (_jsx("ul", { className: "ml-5 list-disc", children: installmentsPayments.map((payment, i) => (_jsxs("li", { className: "font-bold", children: [booking.leadCurrency, " ", formatMoney(payment.data.total, booking.leadCurrency), _jsx("span", { className: "font-extralight", children: i === 0
                                            ? ` ${t('now')}`
                                            : ` ${t('onDate', { date: dayjs().utc().add(i, 'month').startOf('month').add(4, 'days').startOf('day').format('DD MMM YY') })}` })] }, payment._id))) })) : null] })) }), isCreditCard && Boolean(paymentModality) ? (_jsx(CreditCardForm, { children: (isValidForm, checkErrors, { paymentData }) => (_jsx(FinishBooking, { isValidForm: isValidForm, checkErrors: checkErrors, paymentData: paymentData, children: _jsx(Resume, {}) })) })) : null] }));
}
