import { useBooking } from '../contexts/BookingContext';
export default function Debug() {
    const { booking } = useBooking();
    if (!booking)
        return null;
    return null;
    // const finalPriceWithoutFees = getBasePrice({ booking })
    // const customerFee = getTotalCustomerFee({ booking })
    // const depositWithCustomerFee = finalPriceWithoutFees * (DEFAULT_DEPOSIT / 100) + customerFee
    // const totalCcFee = getTotalCcFee(DEFAULT_DEPOSIT, finalPriceWithoutFees, booking)
    // const deposit = finalPriceWithoutFees * (DEFAULT_DEPOSIT / 100)
    // return (
    //   <div className="absolute bottom-0 bg-black px-6 py-2 w-full sm:w-1/2 md:w-1/3 rounded m-4 text-white text-sm">
    { /* <p className='my-2'>finalPriceWithoutFees: <span className='font-bold text-red-500'>{finalPriceWithoutFees}</span> </p>
    <p className='my-2'>customerFee: <span className='font-bold text-red-500'>{customerFee}</span> </p>
    <p className='my-2'>depositWithCustomerFee: <span className='font-bold text-red-500'>{depositWithCustomerFee}</span> </p>
    <p className='my-2'>totalCcFee: <span className='font-bold text-red-500'>{totalCcFee}</span> </p> */
    }
    { /* <p className='my-2'>totalDeposit: <span className='font-bold text-red-500'>{firstPaymentAmount}</span> </p> */ }
    { /* <p className='my-2'>subtotal: <span className='font-bold text-red-500'>{deposit}</span> </p> */ }
    { /* </div>
  ) */
    }
}
