import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { usePayment } from '../../../contexts/PaymentContext';
import { PAYMENT_METHODS } from '../../../lib/payments/constant';
import { useBooking } from '../../../contexts/BookingContext';
import formatMoney from '../../../lib/formatMoney';
import { useI18n } from '../../../contexts/i18nContext';
import CheckBox from '../CheckBox';
import FinishBookingPreConfirmation from '../FinishBookingPreConfirmation';
import { createRef, useEffect } from 'react';
import usePaymentRequest from '../../../hooks/usePaymentRequest';
import { PaymentRequestButtonElement } from '@stripe/react-stripe-js';
const walletText = {
    applePay: 'Apple Pay',
    googlePay: 'Google Pay'
};
export default function AppleAndGooglePay() {
    const ccRef = createRef();
    const { booking } = useBooking();
    const { t } = useI18n();
    const { setPaymentMethod, paymentMethod, paymentModality, isWallet, fullCreditCard, loadingPrices } = usePayment();
    const { canMakePayment, paymentRequest, walletName } = usePaymentRequest();
    useEffect(() => {
        if (isWallet && ccRef.current) {
            ccRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [isWallet, ccRef]);
    if (!canMakePayment || !booking)
        return null;
    return (_jsxs("div", { ref: ccRef, children: [_jsx(CheckBox, { label: _jsxs("div", { className: "w-full flex items-center gap-1", children: [walletName && walletText[walletName], " ", '', loadingPrices ? (_jsx("div", { className: "h-3 mt-1 bg-gray-200 rounded-full dark:bg-gray-200 w-1/6" })) : (_jsxs("span", { className: "text-sm font-extralight flex items-center", children: ["(+", booking.leadCurrency, " ", formatMoney(fullCreditCard.ccFee, booking.leadCurrency), ")"] }))] }), isActive: isWallet, setPaymentModality: () => {
                    if (paymentMethod === PAYMENT_METHODS.WALLETS)
                        return setPaymentMethod(null);
                    return setPaymentMethod(PAYMENT_METHODS.WALLETS);
                }, children: _jsxs("div", { className: "flex items-center gap-1", children: [t('youWillPayATotal'), ' ', loadingPrices ? (_jsx("div", { className: "h-3 mt-1 bg-gray-200 rounded-full dark:bg-gray-200 w-1/6" })) : (_jsxs("strong", { children: [booking.leadCurrency, " ", formatMoney(fullCreditCard.amount, booking.leadCurrency)] }))] }) }), isWallet && Boolean(paymentModality) ? (_jsx(FinishBookingPreConfirmation, { isValidForm: true, children: (readTerms) => (_jsx("div", { className: "flex gap-1 w-full cursor-pointer mb-5 flex-col relative", children: _jsxs(_Fragment, { children: [_jsx(PaymentRequestButtonElement, { onClick: (e) => {
                                    if (!readTerms)
                                        e.preventDefault();
                                }, className: `w-full ${!readTerms && 'opacity-30'}`, options: {
                                    paymentRequest
                                } }), !readTerms && _jsx("div", { className: "absolute w-full h-full cursor-auto top-0" })] }) })) })) : null] }));
}
