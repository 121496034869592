import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import dayjs from 'dayjs';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { saveTravellersInformation } from '../../api-requests/booking';
import Input from '../../components/tailwind/Input';
import { useBooking } from '../../contexts/BookingContext';
import { useI18n } from '../../contexts/i18nContext';
export function AdditionalTravellersInfo() {
    const { booking } = useBooking();
    const { t } = useI18n();
    const [infoSaved, setInfoSaved] = useState(false);
    if (!booking)
        return null;
    if (booking.people_count === 1)
        return null;
    const { people_count, travellers, unid_travellers } = booking;
    const allTravellers = [...travellers, ...unid_travellers];
    const extraTravelers = Array.from(new Array(people_count - 1)).map((v, i) => allTravellers[i] ?? {
        first_name: '',
        last_name: '',
        email: '',
        updated_email: '',
        birthdate: '',
    });
    async function handleTravellers(e) {
        e.preventDefault();
        const travellers = [...extraTravelers];
        const { birthdate, fullName, email } = e.target;
        if (birthdate) {
            if ((birthdate.length || 0) > 1) {
                birthdate.forEach((v, i) => {
                    travellers[i].birthdate = v.value;
                });
            }
            else {
                travellers[0].birthdate = birthdate.value;
            }
        }
        if (fullName) {
            if ((fullName.length || 0) > 1) {
                fullName.forEach((v, i) => {
                    const [first, ...rest] = v.value.split(' ');
                    travellers[i].first_name = first;
                    travellers[i].last_name = rest.join(' ');
                });
            }
            else {
                const [first, ...rest] = fullName.value.split(' ');
                travellers[0].first_name = first;
                travellers[0].last_name = rest.join(' ');
            }
        }
        if (email) {
            if ((email.length || 0) > 1) {
                email.forEach((v, i) => {
                    const k = (() => {
                        const em = travellers[i].email;
                        if (!em)
                            return 'email';
                        return em === v.value.trim() ? 'email' : 'updated_email';
                    })();
                    travellers[i][k] = v.value.trim();
                });
            }
            else {
                const k = (() => {
                    const em = travellers[0].email;
                    if (!em)
                        return 'email';
                    return em === email.value.trim() ? 'email' : 'updated_email';
                })();
                travellers[0][k] = email.value.trim();
            }
        }
        await toast.promise(saveTravellersInformation(booking?._id, travellers), {
            loading: t('thankYouPage.savingTravellersDetails'),
            success: t('thankYouPage.savingTravellersDetailsSuccess'),
            error: t('thankYouPage.savingTravellersDetailsError'),
        });
        setInfoSaved(true);
    }
    if (infoSaved) {
        return (_jsx("div", { children: _jsx("h3", { className: "mt-10 mb-6 text-lg font-bold", children: t('thankYouPage.thankYouTravellers') }) }));
    }
    return (_jsxs("div", { className: "mb-10", children: [_jsx("h3", { className: "mt-6 mb-2 font-bold text-lg", children: t('thankYouPage.additionalTravellersInfo') }), _jsxs("form", { onSubmit: handleTravellers, children: [extraTravelers.map((traveller, index) => (_jsx(ExtraTraveller, { index: index, traveller: traveller }, index))), _jsx("div", { className: "flex justify-end mt-2", children: _jsx("button", { className: "primary-button text-sm", type: "submit", children: t('thankYouPage.saveTravellersInformation') }) })] })] }));
}
function checkEmail(v) {
    return (!v ||
        (v &&
            !v
                .trim()
                .match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)));
}
function ExtraTraveller({ index, traveller }) {
    const { t } = useI18n();
    const diff = traveller.birthdate ? dayjs(traveller.birthdate).diff(dayjs(), 'years') : 18;
    const [isAdult, setIsAdult] = useState(Math.abs(diff) >= 18);
    const [birthDate, setBirthdate] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState({ email: false, name: false });
    return (_jsxs("div", { className: "my-4", children: [_jsxs("p", { className: "font-bold mb-2", children: [t('thankYouPage.participantN'), " ", index + 1] }, index), _jsxs("label", { className: "text-sm", children: ["Birthday:", _jsx("div", { children: _jsx("input", { defaultValue: traveller.birthdate ? dayjs.utc(traveller.birthdate).format('YYYY-MM-DD') : undefined, type: "date", id: "birthdate", name: "birthdate", className: "p-2 border border-gray-200 rounded-md", value: birthDate, onChange: e => {
                                let v = e.target.value;
                                const sp = v.split('-');
                                if (Number(sp[0]) > dayjs().year()) {
                                    sp[0] = dayjs().year().toString();
                                    v = sp.join('-');
                                }
                                if (dayjs(v).isAfter(dayjs())) {
                                    v = dayjs().format('YYYY-MM-DD');
                                }
                                setBirthdate(v);
                                const diff = dayjs(v).diff(dayjs(), 'years');
                                setIsAdult(Math.abs(diff) >= 18);
                            } }) })] }), _jsxs("div", { className: "block md:flex gap-2", children: [_jsx("div", { className: "w-full md:w-1/2", children: _jsx(Input, { defaultValue: traveller.first_name || traveller.last_name
                                ? `${traveller.first_name || ''} ${traveller.last_name || ''}`
                                : undefined, name: "fullName", label: t('thankYouPage.fullName'), placeholder: "First name and last name", onChange: v => {
                                setName(v);
                                setErrors({
                                    ...errors,
                                    name: v.length <= 0,
                                });
                            }, onBlur: () => {
                                setErrors({
                                    ...errors,
                                    name: name.length <= 0,
                                });
                            }, isValid: !errors.name, required: true }) }), _jsx("div", { className: `w-full md:w-1/2 ${isAdult ? 'block' : 'hidden'}`, children: _jsx(Input, { defaultValue: traveller.email, value: email, label: "Email", name: "email", placeholder: "Email", id: "email", onChange: v => {
                                setErrors({
                                    ...errors,
                                    email: Boolean(checkEmail(v)),
                                });
                                setEmail(v);
                            }, onBlur: () => {
                                setErrors({
                                    ...errors,
                                    email: Boolean(checkEmail(email)),
                                });
                            }, isValid: !errors.email }) })] })] }));
}
