const esTable = {
    availability: 'Disponibilidad',
    groupSize: 'Tamaño del grupo',
    price: 'Precio',
    errorTitle: 'Algo salió mal con tu pago',
    errorDesc: 'Por favor, verifica si la información ingresada es correcta o consulta el estado de la tarjeta con tu banco.',
    goBackToCheckout: 'Volver al pago',
    address: 'Dirección',
    addressNumber: 'Número de dirección',
    zipCode: 'Código postal',
    city: 'Ciudad',
    country: 'País',
    selectACountry: 'Selecciona un país...',
    secureCardInfo: 'Información segura de la tarjeta de crédito',
    nameOnCard: 'Nombre en la tarjeta',
    cardNumber: 'Número de tarjeta',
    expirationDate: 'Fecha de vencimiento',
    securityCode: 'Código de seguridad',
    youWillPreAuthorize: 'Autorizarás un cargo por',
    wontBeChargedYet: '¡No te preocupes! Todavía no se te cobrará. Se te cobrará solo si el guía confirma su disponibilidad.',
    asPerToRemainingBalance: 'En cuanto al saldo restante',
    automaticallyCharged: 'se cargará automáticamente a tu tarjeta 45 días antes de la fecha de inicio de tu viaje.',
    haveToTransfer: 'tendrás que transferirnos de acuerdo a las instrucciones a continuación.',
    personalInformation: 'Información personal',
    firstName: 'Nombre',
    lastName: 'Apellido',
    phone: 'Teléfono',
    phoneNumber: 'Número de teléfono',
    instructionsForBankTransfer: 'Instrucciones para la transferencia bancaria',
    payeeName: 'Nombre del beneficiario',
    reference: 'Referencia',
    ach: 'Número de ruta ACH',
    accountNumber: 'Número de cuenta',
    type: 'Tipo',
    wireNumber: 'Número de transferencia bancaria',
    bankAddress: 'Dirección del banco',
    localUsTransfer: 'Transferencias locales en EE. UU.',
    checkings: 'Cuenta corriente',
    international: 'Internacional',
    US: 'Estados Unidos de América',
    bankCode: 'Código bancario',
    belgium: 'Bélgica',
    austria: 'Austria',
    netherlands: 'Países Bajos',
    spain: 'España',
    italy: 'Italia',
    finland: 'Finlandia',
    france: 'Francia',
    greece: 'Grecia',
    ireland: 'Irlanda',
    portugal: 'Portugal',
    germany: 'Alemania',
    uk: 'Reino Unido',
    switzerland: 'Suiza',
    norway: 'Noruega',
    sweden: 'Suecia',
    poland: 'Polonia',
    pay75daysLater: (currency, totalAmountToChargeLater) => `En cuanto al saldo restante (${currency} ${totalAmountToChargeLater}) tendrás que transferirnos de acuerdo a las instrucciones anteriores. Deberás hacerlo a más tardar 45 días antes de la fecha de inicio de tu viaje, de lo contrario, el viaje se cancelará.`,
    wireNotice: 'Si eliges pagar mediante transferencia bancaria, tu reserva no se confirmará hasta que envíes el recibo del pago a través de nuestro sistema de gestión de reservas. Recibirás un correo electrónico con instrucciones sobre cómo hacerlo.',
    payInFull: 'Pagar el total',
    payLessUpfront: 'Pagar menos por adelantado',
    ccNowRestLater: 'con tarjeta de crédito ahora, el resto después',
    howPaymentWillOccur: 'Cómo se realizará el pago:',
    youHaveChosen: 'Has elegido:',
    choosePaymentMethod: 'Elige tu método de pago',
    choosePaymentMethodForRemaining: 'Elige tu método de pago para el importe restante',
    forRemainingAmount: 'Para el importe restante',
    chooseWheterToPay: 'elige si pagar con tarjeta de crédito o transferencia bancaria.',
    creditCard: 'Tarjeta de crédito',
    charged45DaysBeforeDP: 'se te cobrará 45 días antes de tu partida',
    toBePaid45DaysBeforeDP: 'a pagar 45 días antes de tu partida',
    youWillPayATotal: 'Pagarás un total de',
    bankTransfer: 'Transferencia bancaria',
    weWillProvideInstructions: 'Te proporcionaremos instrucciones detalladas por correo electrónico',
    readAndAccept: 'He leído y acepto los',
    termsAndConditions: 'Términos y condiciones de uso',
    paymentError: 'Error de pago',
    billingInformation: 'Información de facturación',
    securePaymentInfo: 'Información de pago seguro',
    howToPay: '¿Cómo te gustaría pagar?',
    requestToBook: 'Solicitar reserva',
    chooseHowToPay: 'Elige cómo pagar',
    paymentMethodFee: 'Cargo por método de pago',
    paymentProcessingFee: 'Cargo por procesamiento de pago',
    changePaymentMethod: 'Cambiar método de pago',
    changePayment: 'Cambiar pago',
    confirmAndPay: 'Confirmar detalles y pagar',
    paymentInfo: 'Información de pago',
    otherPaymentMethods: 'Otros métodos de pago (a través de Stripe)',
    paymentPoweredByStripe: 'Pago seguro en línea con tecnología Stripe',
    pricePerPerson: 'Precio por persona',
    travellers: 'Viajeros',
    serviceFee: 'Cargo por servicio',
    creditCardFee: 'Cargo por tarjeta de crédito',
    stripeFee: 'Cargo de Stripe',
    total: 'Total',
    subTotal: 'Subtotal',
    thankYouPage: {
        title: '¡Gracias!',
        reservationCode: 'Código de reserva:',
        trip: 'Viaje:',
        departureDate: 'Fecha de salida:',
        returnToMyDashboard: 'Volver a mi panel',
        updateBillingInfo: 'Actualizar mi información de facturación',
        goToTripPage: 'Ir a la página del viaje',
        wireInstructionsForDepositSent: 'Las instrucciones para pagar mediante transferencia bancaria el importe restante de este viaje se enviaron por correo electrónico. Contacta con nuestro equipo si necesitas más información.',
        wireInstructionsSent: 'Las instrucciones para pagar mediante transferencia bancaria se enviaron por correo electrónico. Contacta con nuestro equipo si necesitas más información.',
        otherOptions: 'Otras opciones:',
        completeBillingDetails: 'Por favor, completa tus datos de facturación',
        saveBillingDetails: 'Guardar detalles de facturación',
        savingBillingDetails: 'Guardando detalles de facturación...',
        savingBillingDetailsError: 'Error al guardar los detalles de facturación',
        savingBillingDetailsSuccess: 'Detalles de facturación guardados con éxito',
        savingTravellersDetails: 'Guardando informacion de los pasajeros...',
        savingTravellersDetailsError: 'Error al guardar los detalles de los pasajeros',
        savingTravellersDetailsSuccess: 'Detalles de los pasajeros guardados con éxito',
        confirmationSentByEmail: 'Te hemos enviado la confirmación de la reserva por correo electrónico.',
        seeBankDetails: 'Ver detalles bancarios',
        thankYouBilling: 'Gracias por completar tus datos de facturación, puedes continuar con:',
        thankYouTravellers: 'Gracias por completar la informacion de los viajeros',
        followUsOnIg: 'Síguenos en Instagram',
        followUsOnIgRest: 'para recibir tu dosis semanal de emocionantes aventuras e inspiración viajera.',
        joinReferral: 'Únase a nuestro programa de referidos',
        joinReferralRest: 'y gane 50 $ para usted y su amigo cuando reserven con nosotros.',
        additionalTravellersInfo: 'Informacion sobre los pasajeros adicionales',
        participantN: 'Participante Nº',
        fullName: 'Nombre completo',
        saveTravellersInformation: 'Guardar informacion de los pasajeros',
        isAdult: 'Es mayor de edad?',
    },
    whetherToPay: 'Para el importe restante <strong>({{ price }})</strong> elige si pagar con tarjeta de crédito o transferencia bancaria.',
    payNow: 'Pagar ahora',
    payLater: 'Pagar después',
    tripDays: 'Duración del viaje (días)',
    secureCcInformation: 'Información segura de la tarjeta de crédito',
    now: 'ahora',
    succeededPayment: 'Pago procesado con éxito',
    processingPayment: 'Procesando el pago',
    paymentFailed: 'Error al procesar el pago. Revisa los datos de tu tarjeta.',
    insufficient_funds: 'Error al procesar el pago. Fondos insuficientes en tu tarjeta.',
    generic_decline: 'Error al procesar el pago. Tu tarjeta ha sido rechazada.',
    lost_card: 'Error al procesar el pago. Tu tarjeta se ha perdido.',
    stolen_card: 'Error al procesar el pago. Tu tarjeta ha sido robada.',
    do_not_honor: 'Error al procesar el pago. Contacta con el emisor de tu tarjeta para autorizar el pago.',
    redirectingToStripe: 'Redireccionando a Stripe...',
    errorRedirectingToStripe: 'Error al redirigir a Stripe',
    instructionsWillBeSentByMail: 'Las instrucciones se enviarán a tu correo electrónico ({{email}})',
    changeCurrency: 'Cambiar moneda',
    closeDetails: 'Cerrar detalles',
    showPricingDetails: 'Mostrar detalles de precios',
    installment: 'Cuota',
    priceMayVary: 'Ten en cuenta que el precio en tu moneda puede variar según el tipo de cambio del día.',
    guideCurrency: 'La moneda del guía es',
    finishBooking: 'Finalizar reserva',
    confirmBooking: 'Confirmar reserva',
    pay: 'Pagar',
    payPartNowPartLater: 'Pagar parte ahora, parte después',
    dueToday: 'hoy',
    dueLater: 'el {{date}}. Sin cargos adicionales.',
    onDate: 'el {{date}}',
    payInInstallments: 'en cuotas',
    noExtraFees: 'Sin cargos adicionales',
    continueToKlarna: 'Continuar con Klarna',
    inNInstallmentsOf: 'En {{installments}} cuotas de',
    payInNInstallments: 'Pagar en {{installments}} cuotas',
    usingKlarna: 'Usando Klarna',
    interestFree: 'sin intereses',
    extraFee: 'Cargo adicional:',
    totalToPayNow: 'Total a pagar ahora:',
    installmentAlreadyPaid: 'Cuota ya pagada',
    invalidInstallment: 'Esta cuota no se puede pagar con tarjeta de crédito. Ponte en contacto con nuestro equipo de ventas.',
    installmentNotFound: 'Cuota no encontrada',
    subtotalPerPerson: 'Subtotal por persona (depósito {{ percentage }}%)',
    freeCancellation: 'Cancelación gratuita de 48 horas con reserva anticipada de 30 días',
    askQuestions: 'Preguntar',
    needAditionalServices: '¿Necesitas servicios adicionales?',
    accommodation: 'Buscar alojamiento: Reserve su estancia',
    insurance: 'Cúbrase: Seguro de viaje y rescate',
    gear: 'Compra equipo: Consigue lo mejor de las mejores marcas',
    flights: 'Reserva vuelos: Reserva de vuelos',
    car: 'Alquilar un coche: Servicios de alquiler de coches',
    exploreSafely: 'Explora la montaña con seguridad: Aprenda en línea',
    here: 'AQUÍ',
};
export default esTable;
