import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useBooking } from '../../../contexts/BookingContext';
import { useI18n } from '../../../contexts/i18nContext';
import { usePayment } from '../../../contexts/PaymentContext';
import useRedirectToStripe from '../../../hooks/useRedirectToStripe';
import formatMoney from '../../../lib/formatMoney';
import { PAYMENT_METHODS, PAYMENT_MODALITIES } from '../../../lib/payments/constant';
import Klarna from '../../Icons/Klarna';
import CheckBox from '../CheckBox';
import Amplitude from '../../../lib/Amplitude';
export default function PromotedMethod() {
    const { t } = useI18n();
    const { booking } = useBooking();
    const { stripePromotedMethodUrl } = useRedirectToStripe();
    const [showPromotedMethod, setShowPromotedMethod] = useState(false);
    const { setPaymentMethod, setPaymentModality, loadingPrices, isInstallments, fullCreditCard, installmentsPayments, } = usePayment();
    useEffect(() => {
        Amplitude.getVariant('202407-promote-payment-method-klarna').then(variant => {
            setShowPromotedMethod(variant === 'show-promoted-payment-method');
        });
    }, []);
    const klarnaCountries = [
        `🇩🇪 ${t('germany')}`,
        `🇦🇹 ${t('austria')}`,
        `🇫🇮 ${t('finland')}`,
        `🇳🇱 ${t('netherlands')}`,
        `🇧🇪 ${t('belgium')}`,
        `🇫🇷 ${t('france')}`,
        `🇮🇹 ${t('italy')}`,
        `🇪🇸 ${t('spain')}`,
        `🇵🇹 ${t('portugal')}`,
        `🇬🇧 ${t('uk')}`,
        `🇨🇭 ${t('switzerland')}`,
        `🇳🇴 ${t('norway')}`,
        `🇸🇪 ${t('sweden')}`,
        `🇵🇱 ${t('poland')}`,
    ];
    if (!booking)
        return null;
    if (stripePromotedMethodUrl === undefined)
        return null; // is loading
    if (stripePromotedMethodUrl && showPromotedMethod) {
        return (_jsxs(_Fragment, { children: [_jsxs("dialog", { className: "rounded-md", id: "countries-list", onClick: event => {
                        const dialog = document.querySelector('dialog');
                        if (event.target === dialog) {
                            dialog.close();
                        }
                    }, children: [_jsx("button", { onClick: () => document.getElementById('countries-list')?.close(), children: "Close" }), _jsx("div", { className: "py-2 px-4", children: _jsx("ul", { children: klarnaCountries
                                    .sort((a, b) => a.localeCompare(b))
                                    .map(country => (_jsx("li", { className: "py-1", children: country }, country))) }) })] }), _jsx(CheckBox, { isActive: isInstallments, setPaymentModality: () => {
                        setPaymentMethod(PAYMENT_METHODS.STRIPE);
                        setPaymentModality(PAYMENT_MODALITIES.INSTALLMENTS);
                    }, label: _jsxs("div", { className: "w-full flex items-center gap-1", children: [t('pay'), ' ', loadingPrices ? (_jsx("div", { className: "h-3 mt-1 bg-gray-200 rounded-full dark:bg-gray-200 w-1/6" })) : (`${booking.leadCurrency} ${formatMoney(fullCreditCard.amount, booking.leadCurrency)}`), ' ', t('payInInstallments')] }), outsideChildren: _jsx("button", { className: "text-main underline px-4 pb-4", onClick: () => {
                            const dialog = document.getElementById('countries-list');
                            dialog?.showModal();
                        }, children: "See available countries." }), children: _jsx("div", { children: _jsxs("div", { className: "flex items-center gap-1", children: [t('usingKlarna'), " ", _jsx(Klarna, {}), ", ", t('interestFree'), "."] }) }) })] }));
    }
    return installmentsPayments.length > 1 ? (_jsx(CheckBox, { isActive: isInstallments, setPaymentModality: () => {
            setPaymentMethod(PAYMENT_METHODS.CREDIT_CARD);
            setPaymentModality(PAYMENT_MODALITIES.INSTALLMENTS);
        }, label: _jsxs("div", { className: "w-full flex items-center gap-1", children: [t('pay'), ' ', loadingPrices ? (_jsx("div", { className: "h-3 mt-1 bg-gray-200 rounded-full dark:bg-gray-200 w-1/6" })) : (`${booking.leadCurrency} ${formatMoney(fullCreditCard.amount, booking.leadCurrency)}`), ' ', t('payInInstallments')] }), children: _jsx("div", { children: _jsxs("div", { className: "flex items-center gap-1", children: [t('payInNInstallments', { installments: installmentsPayments.length }), ",", ' ', t('interestFree'), ".", loadingPrices ? (_jsxs(_Fragment, { children: [_jsx("div", { className: "h-3 mt-1 bg-gray-200 rounded-full dark:bg-gray-200 w-1/6" }), "."] })) : null] }) }) })) : null;
}
