import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import Input from '../../tailwind/Input';
import { formatCreditCard, formatExpiry, getCreditCardBrand, getMaxCardLength, } from '../../../lib/payments/cc-form-utils';
import AmExIcon from '../../Icons/AmExIcon';
import Discover from '../../Icons/Discover';
import MasterCardIcon from '../../Icons/MasterCardIcon';
import VisaIcon from '../../Icons/VisaIcon';
import UnknownCard from '../../Icons/UnknownCard';
import { useBooking } from '../../../contexts/BookingContext';
import { useI18n } from '../../../contexts/i18nContext';
import { usePayment } from '../../../contexts/PaymentContext';
import formatMoney from '../../../lib/formatMoney';
import DinersClub from '../../Icons/DinersClub';
import Lock from '../../Icons/Lock';
const cardIcon = {
    amex: AmExIcon,
    discover: Discover,
    mastercard: MasterCardIcon,
    visa: VisaIcon,
    dinersclub: DinersClub,
    unknown: UnknownCard,
};
const CreditCardForm = ({ children, }) => {
    const { booking } = useBooking();
    const { t } = useI18n();
    const { depositWithCard, isUpfrontPayment } = usePayment();
    const [cvc, setCvc] = useState('');
    const [expiry, setExpiry] = useState('');
    const [name, setName] = useState('');
    const [cardNumber, setCardNumber] = useState('');
    const [errors, setErrors] = useState(null);
    const CardIcon = cardIcon[getCreditCardBrand(cardNumber)];
    const isValidForm = Boolean(cvc.length >= 3 && expiry.match(/^(0[1-9]|1[0-2])\/?([0-9]{2})$/) && name && cardNumber);
    const paymentData = {
        cvv: cvc,
        expiry,
        name,
        number: cardNumber,
    };
    function checkErrors() {
        const cardLength = getMaxCardLength(formatCreditCard(cardNumber));
        const validCard = formatCreditCard(cardNumber).length === cardLength;
        const newErrors = {
            cc: !validCard,
            expiry: !expiry.match(/^(0[1-9]|1[0-2])\/?([0-9]{2})$/),
            cvc: cvc.length < 3,
            name: !name,
        };
        setErrors(newErrors);
        if (Object.values(newErrors).some(v => v)) {
            throw newErrors;
        }
    }
    return (_jsxs("div", { className: "my-5", children: [_jsxs("div", { className: "flex mb-1 gap-3", children: [_jsx(AmExIcon, {}), _jsx(MasterCardIcon, {}), _jsx(VisaIcon, {})] }), _jsx(CreditCardFormField, { isValid: !errors?.cc, label: _jsxs("p", { className: "flex items-center", children: [t('cardNumber'), " ", _jsx(Lock, { color: "gray", size: 12 })] }), placeholder: "4444 4444 4444 4444", fn: (value) => {
                    if (!/^[0-9\s]*$/g.test(value))
                        return;
                    const cardLength = getMaxCardLength(value);
                    if (value.length > cardLength)
                        return;
                    setCardNumber(value);
                }, validateFn: (value) => {
                    const cardLength = getMaxCardLength(value);
                    return value.length === cardLength;
                }, value: formatCreditCard(cardNumber), name: "number", icon: _jsx(CardIcon, {}), autoFocus: true, type: "text", inputmode: "decimal", pattern: "[0-9\\s]*", setIsValid: isValid => {
                    setErrors(prev => ({ ...prev, cc: !isValid }));
                } }, "number"), _jsxs("div", { className: "flex gap-1", children: [_jsx(CreditCardFormField, { isValid: !errors?.expiry, label: t('expirationDate'), placeholder: "MM/YY", fn: (value) => {
                            if (value.length > 5)
                                return;
                            setExpiry(prev => {
                                if (value.length === 2 && prev.length === 1) {
                                    return value + '/';
                                }
                                if (value.length === 2 && prev.length === 3) {
                                    return value.slice(0, 1);
                                }
                                return value;
                            });
                        }, validateFn: (value) => value.match(/^(0[1-9]|1[0-2])\/?([0-9]{2})$/) !== null, value: formatExpiry(expiry), name: "expiry", className: "w-1/2", inputmode: "decimal", type: "text", setIsValid: isValid => {
                            setErrors(prev => ({ ...prev, expiry: !isValid }));
                        } }, "expiry"), _jsx(CreditCardFormField, { isValid: !errors?.cvc, label: "CVC", placeholder: "412", fn: (value) => {
                            if (!/^[0-9]+$/g.test(value) && value !== '')
                                return;
                            if (value.length > 4)
                                return;
                            setCvc(value);
                        }, validateFn: (value) => value.length === 3 || value.length === 4, value: cvc, name: "cvc", className: "w-1/2", type: "number", pattern: "[0-9]*", inputmode: "numeric", setIsValid: isValid => {
                            setErrors(prev => ({ ...prev, cvc: !isValid }));
                        } }, "cvc")] }), _jsx(CreditCardFormField, { isValid: !errors?.name, label: t('nameOnCard'), placeholder: "John Doe", fn: setName, validateFn: (value) => value.length > 0, setIsValid: isValid => {
                    setErrors(prev => ({ ...prev, name: !isValid }));
                }, value: name, name: "name", type: "text" }, "name"), isUpfrontPayment && booking && booking.guide_state !== 'G:CONFIRMED' ? (_jsxs("div", { className: "font-extralight p-4 my-4 bg-[#f3f3f3]", children: [t('youWillPreAuthorize'), ' ', _jsxs("strong", { children: [' ', booking.leadCurrency, ' ', formatMoney(depositWithCard.deposit.amount, booking.leadCurrency)] }), ". ", t('wontBeChargedYet')] })) : null, children(isValidForm, checkErrors, { paymentData })] }));
};
function CreditCardFormField({ name, isValid, placeholder, fn, value, label, icon, autoFocus = false, className, type, pattern, inputmode, validateFn, setIsValid, }) {
    return (_jsxs("label", { className: className, children: [_jsx("span", { className: "text-sm font-normal text-gray-900 mt-2", children: label }), _jsx("div", { className: "relative", children: _jsx(Input, { name: name, placeholder: placeholder, onChange: v => {
                        setIsValid(true);
                        fn(v);
                    }, errorLabels: "Invalid value", isValid: isValid ?? false, required: true, value: value, endDecorator: icon, autoFocus: autoFocus, type: type, pattern: pattern, inputMode: inputmode, onBlur: () => {
                        validateFn && setIsValid(validateFn(value));
                    } }, name) })] }, name));
}
export default CreditCardForm;
