import fetcher from './fetcher';
export function changePaymentMethod(bookingId, body) {
    return fetcher({
        url: `/api/v1/dashboard/bookings/change-payment-method/${bookingId}`,
        data: body,
        method: 'POST'
    });
}
export function preAuthorize(bookingId, body) {
    return fetcher({
        url: `/api/v1/payment/pre-authorize/${bookingId}`,
        data: body,
        method: 'POST'
    });
}
export function confirmPayment(bookingId) {
    return fetcher({
        url: `/api/v1/payment/confirm-payment/${bookingId}`,
        method: 'POST'
    });
}
export function createPaymentIntent(bookingId, walletName) {
    return fetcher({
        url: '/api/v3/stripe/create-payment-intent',
        method: 'POST',
        data: {
            bookingId,
            walletName
        }
    });
}
export function confirmPaymentIntent(bookingId, paymentIntentId, walletName, opts) {
    return fetcher({
        url: '/api/v3/stripe/confirm-payment-intent',
        method: 'POST',
        data: {
            bookingId,
            paymentIntentId,
            error: opts.error,
            walletName
        }
    });
}
export function capturePaymentIntent(bookingId, paymentIntentId) {
    return fetcher({
        url: '/api/v3/stripe/capture-payment-intent',
        method: 'POST',
        data: {
            bookingId,
            paymentIntent: paymentIntentId
        }
    });
}
