import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import getAcceptedCurrencies from '../../api-requests/currencies';
import updateLeadCurrency from '../../api-requests/lead';
import { useBooking } from '../../contexts/BookingContext';
import { useI18n } from '../../contexts/i18nContext';
import CustomCombobox from '../tailwind/Combobox';
function useFetchAcceptedCurrencies() {
    const [currencies, setCurrencies] = useState(null);
    useEffect(() => {
        getAcceptedCurrencies().then(data => setCurrencies(data.currencies));
    }, []);
    return currencies;
}
export default function ChangeCurrency() {
    const { t } = useI18n();
    const { changeCurrency, booking } = useBooking();
    const currencies = useFetchAcceptedCurrencies();
    if (!booking || !currencies)
        return null;
    const handleChangeCurrency = async (currency) => {
        await updateLeadCurrency({ leadId: booking.lead._id, currency });
        changeCurrency(currency);
    };
    return (_jsxs("div", { className: "flex justify-between items-center gap-2", children: [_jsx("div", { className: "w-1/2", children: _jsx("p", { className: "text-sm", children: t('changeCurrency') }) }), _jsx("div", { className: "w-1/2 h-[35px]", children: _jsx(CustomCombobox, { position: "bottom", options: currencies.map((c, i) => ({ title: c, id: i })), defaultValue: booking.leadCurrency, handleOnChange: value => handleChangeCurrency(value.title) }) })] }));
}
