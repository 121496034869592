import fetcher from './fetcher';
export function getBookingById(bookingId) {
    return fetcher({
        url: `/api/v1/status/bookings/${bookingId}`,
        method: 'GET',
    });
}
export function saveTravellersInformation(bookingId, travellers) {
    return fetcher({
        url: `/api/v1/widget/bookings/extra-info/${bookingId}/travellers`,
        method: 'POST',
        data: { travellers },
    });
}
export function persistPriceCalculation({ paymentMethod, bookingId, options, }) {
    return fetcher({
        url: `/api/v1/status/bookings/${bookingId}/price-calculation`,
        method: 'PATCH',
        data: {
            paymentMethod,
            options,
        },
    });
}
