import * as Ampli from '@amplitude/analytics-browser';
import { Experiment } from '@amplitude/experiment-js-client';
class Amplitude {
    apiKey;
    isInitialized = false;
    userIdentifyEvent = null;
    instance = null;
    origin = 'es-checkout';
    experiment = null;
    isInitializedExperiment = false;
    constructor() {
        this.apiKey = process.env.AMPLITUDE_API_KEY;
    }
    //
    initialize(plugins = []) {
        if (this.apiKey && !this.isInitialized) {
            this.instance = Ampli.createInstance();
            plugins.forEach((plugin) => {
                this.instance?.add(plugin);
            });
            this.instance.init(this.apiKey);
            this.isInitialized = true;
        }
    }
    setOrigin(origin) {
        this.origin = origin;
    }
    setUser(properties) {
        this.initialize();
        if (!this.userIdentifyEvent) {
            this.userIdentifyEvent = new Ampli.Identify();
        }
        Object.entries(properties).forEach(([key, value]) => {
            this.userIdentifyEvent?.set(key, value);
        });
        this.instance?.identify(this.userIdentifyEvent);
    }
    setUserId(id) {
        this.instance?.setUserId(id);
    }
    trackEvent(eventName, eventProperties) {
        this.initialize();
        this.instance?.track(`${eventName}`, { ...eventProperties });
    }
    pageViewed(eventProperties) {
        //
        this.initialize([
            {
                name: 'page-view-tracking-enrichment',
                type: 'enrichment',
                setup: async () => undefined,
                execute: async (event) => {
                    event.event_properties = {
                        ...event.event_properties,
                        'tech_origin': this.origin,
                    };
                    if (event.event_type !== '[Amplitude] Page Viewed')
                        return event;
                    // PageView
                    event.event_properties = {
                        ...event.event_properties,
                        ...eventProperties,
                    };
                    return event;
                },
            },
        ]);
    }
    initializeExperiment() {
        if (this.apiKey && !this.isInitializedExperiment) {
            this.experiment = Experiment.initializeWithAmplitudeAnalytics(this.apiKey);
            this.isInitializedExperiment = true;
        }
    }
    async getVariant(experimentKey) {
        if (!this.initializeExperiment || !experimentKey || !this.apiKey)
            return false;
        if (!this.experiment) {
            this.experiment = Experiment.initializeWithAmplitudeAnalytics(this.apiKey);
        }
        // @ts-ignore
        if (!this.experiment?.isRunning) {
            await this.experiment.start();
        }
        return this.experiment.variant(experimentKey).value;
    }
}
const instanceAmplitude = new Amplitude();
export default instanceAmplitude;
export const Events = {
    PAYMENT_SUBMITED: 'Payment Submitted'
};
