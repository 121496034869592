import { purchaseDataLayer } from '../lib/payments/post-payment/analytics';
import Amplitude, { Events } from '../lib/Amplitude';
const PAYMENT_METHODS = {
    cc: 'CREDIT_CARD',
    wire: 'WIRE',
    deposit: 'DEPOSIT',
    stripe: 'STRIPE',
    none: 'NONE',
    installments: 'INSTALLMENTS',
    cash: 'CASH',
    group: 'GROUP',
    applePay: 'APPLE_PAY',
    googlePay: 'GOOGLE_PAY',
    ideal: 'iDEAL',
    sofort: 'SOFORT',
    bancontact: 'BANCONTACT',
    giropay: 'GIROPAY',
    eps: 'EPS',
    sepa_debit: 'SEPA_DIRECT_DEBIT',
};
export function Analytics(booking, newBooking, installment, revenue, installmentIndex, origin) {
    if (installment?.data.fragment?.method !== 'wire') {
        purchaseDataLayer({
            booking: newBooking,
            revenue,
        });
    }
    //amplitude
    if (newBooking?.payment_plan && installment) {
        try {
            const { payment_plan, code, trip, payment_method, people_count, guide_comission, payments } = newBooking;
            const rateToEUR = payment_plan.config.RATES[payment_plan.original.currency]['EUR'];
            const installmentOriginal = payment_plan.originalCurrencyFinal[installmentIndex ?? 0];
            const payment_amount = installmentOriginal.data.total * rateToEUR;
            const summaryCcFee = installmentOriginal.data.summary.filter(item => item.name === 'credit_card_fee')[0];
            const hiddenExfee = installmentOriginal.data.hidden.filter(item => item.name === 'exchange_fee')[0];
            const summaryCustomerfee = installmentOriginal.data.summary.filter(item => ['customer_fee', 'compensation_customer_fee'].includes(item.name))[0];
            const ccFee = summaryCcFee ? summaryCcFee.value * rateToEUR : 0;
            const exFee = hiddenExfee ? hiddenExfee.value * rateToEUR : 0;
            const customerFee = summaryCustomerfee ? summaryCustomerfee.value * rateToEUR : 0;
            const guideFee = installmentOriginal.data.subtotal * (guide_comission / 100) * rateToEUR;
            const totalRevenue = ccFee + exFee + customerFee + guideFee;
            const currentPayment = payments.find(({ installment_id }) => installment_id.toString() === installment._id.toString());
            let data = {};
            const succeededPayments = payments.filter(({ status }) => status === 'succeeded');
            if (payment_method === 'group') {
                // if method is group change some values
                data = {
                    instalment_payment: succeededPayments.length,
                    instalment_total: payment_plan.final.length,
                    travellers: 1,
                };
            }
            else {
                // for others methods
                data = {
                    instalment_payment: (installmentIndex ?? 0) + 1,
                    instalment_total: payment_plan.final.length,
                    travellers: people_count,
                };
            }
            const succeededPaymentsIds = succeededPayments.map(({ installment_id }) => installment_id.toString());
            const paidInstallmentsIndex = payment_plan.final
                .map((item, index) => {
                if (succeededPaymentsIds.includes(item._id.toString()))
                    return index;
                return null;
            })
                .filter(v => v !== null)
                .filter(v => v !== installmentIndex);
            const paidOriginalInstallments = [];
            for (const index of paidInstallmentsIndex) {
                paidOriginalInstallments.push(payment_plan.originalCurrencyFinal[index]);
            }
            const alreadyPaidAmount = paidOriginalInstallments.reduce((acc, item) => {
                return acc + item.data.total;
            }, 0) * rateToEUR;
            const paymentType = (() => {
                const hasDeposit = payment_plan.originalCurrencyFinal.some(item => item.first);
                const hasBalance = payment_plan.originalCurrencyFinal.some(item => !item.first);
                if (payment_method === 'installments') {
                    return 'installment';
                }
                if (installmentOriginal.first && hasBalance) {
                    return 'deposit';
                }
                if (!installmentOriginal.first && hasBalance && hasDeposit) {
                    return 'remaining_balance';
                }
                if (installmentOriginal.first && !hasBalance) {
                    return 'full';
                }
            })();
            Amplitude.trackEvent(Events.PAYMENT_SUBMITED, {
                booking_code: code,
                product_id: trip?._id,
                payment_method: currentPayment?.method ? PAYMENT_METHODS[currentPayment?.method] : '',
                payment_scope: payment_method === 'group' ? 'Split_Payment' : 'Single_Payer',
                payment_type: paymentType,
                trip_currency_client: payment_plan.original.displayCurrency,
                revenue_total: +totalRevenue.toFixed(2),
                revenue_fee_guide: +guideFee.toFixed(2),
                revenue_fee_service: +customerFee.toFixed(2),
                payment_amount: +payment_amount.toFixed(2),
                remaining_balance: +(payment_plan.computedCalculationsEUR.total -
                    alreadyPaidAmount -
                    payment_amount).toFixed(2),
                revenue_fee_cc: +ccFee.toFixed(2),
                revenue_fee_fx: +exFee.toFixed(2),
                source: origin,
                payment_total: +payment_plan.computedCalculationsEUR.total.toFixed(2),
                subtotal: +payment_plan.computedCalculationsEUR.subtotal.toFixed(2),
                ...data,
            });
        }
        catch (e) {
            console.error(e);
        }
    }
}
