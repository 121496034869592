import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import Logo from '../../components/Logo';
import CreditCardForm from '../../components/Payments/CreditCard/CreditCardForm';
import FinishBooking from '../../components/Payments/FinishBooking';
import InstallmentResume from '../../components/Resume/InstallmentResume';
import { useInstallment } from '../../contexts/InstallmentContext';
import { useBooking } from '../../contexts/BookingContext';
import formatMoney from '../../lib/formatMoney';
import { useI18n } from '../../contexts/i18nContext';
import Amplitude from '../../lib/Amplitude';
import PageType from '../../constants/PageType';
const stripePromise = loadStripe(process.env.STRIPE_KEY);
export default function InstallmentPage() {
    const { t } = useI18n();
    const { booking } = useBooking();
    const { getInstallmentToCharge } = useInstallment();
    useEffect(() => {
        const { lead, trip } = booking;
        Amplitude.pageViewed({
            page_type: PageType.common,
            product_id: trip._id,
            activities: trip.activities?.map((activity) => activity.i18n.en_EN.title),
            country: trip.countries[0]?.i18n.en_EN.name,
            region: booking?.trip.regions?.map(v => v.i18n.en_EN.title),
            location: booking?.trip.locations?.map(v => v.i18n.en_EN.title),
        });
        Amplitude?.setUserId(lead._id);
        Amplitude?.setUser({
            user_id: lead._id,
            created: lead.created,
            email: lead.email,
            first_name: lead.first_name,
            last_name: lead.last_name,
            phone: lead.phone,
            preferences: lead.preferences,
        });
    }, []);
    if (!booking)
        return null;
    const { installment, paid, notValid } = getInstallmentToCharge(booking);
    if (paid) {
        return (_jsxs("div", { className: "mb-6", children: [_jsx(Logo, {}), _jsx("div", { className: "flex gap-4 justify-center px-4 w-full lg:w-10/12 xl:w-8/12 2xl:w-6/12 mx-auto", children: _jsx("div", { className: "w-full text-center mt-10", children: _jsx("h1", { className: "text-2xl font-bold", children: t('installmentAlreadyPaid') }) }) })] }));
    }
    if (notValid) {
        return (_jsxs("div", { className: "mb-6", children: [_jsx(Logo, {}), _jsx("div", { className: "flex gap-4 justify-center px-4 w-full lg:w-10/12 xl:w-8/12 2xl:w-6/12 mx-auto", children: _jsx("div", { className: "w-full text-center mt-10", children: _jsx("h1", { className: "text-2xl font-bold", children: t('invalidInstallment') }) }) })] }));
    }
    return (_jsx(Elements, { stripe: stripePromise, children: _jsxs("div", { className: "mb-6", children: [_jsx(Logo, {}), _jsx("div", { className: "flex gap-4 justify-center px-4 w-full lg:w-10/12 xl:w-8/12 2xl:w-6/12 mx-auto", children: !installment ? (_jsx("div", { className: "w-full text-center mt-10", children: _jsx("h1", { className: "text-2xl font-bold", children: t('installmentNotFound') }) })) : (_jsxs(_Fragment, { children: [_jsxs("div", { className: "w-full md:w-3/5 md-0 md:mb-16", children: [_jsxs("h4", { className: "block md:hidden mt-4 text-lg font-bold", children: [t('totalToPayNow'), ' ', `${booking.leadCurrency} ${formatMoney(installment.data.total, booking.leadCurrency)}`] }), _jsx(CreditCardForm, { children: (isValidForm, checkErrors, { paymentData }) => (_jsx(FinishBooking, { checkErrors: checkErrors, isValidForm: isValidForm, paymentData: paymentData, installmentId: installment._id, children: _jsx(InstallmentResume, { installment: installment }) })) })] }), _jsx("div", { className: "w-0 hidden md:w-2/5 md:block", children: _jsx(InstallmentResume, { installment: installment }) })] })) })] }) }));
}
