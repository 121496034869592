import { jsx as _jsx } from "react/jsx-runtime";
import axios from 'axios';
import { createContext, useContext } from 'react';
import { useI18n } from './i18nContext';
const contextDefaultValues = {
    token: '',
    locale: '',
    origin: '',
};
const UserContext = createContext(contextDefaultValues);
export function useUser() {
    return useContext(UserContext);
}
export default function UserProvider({ children }) {
    const { currentLocale } = useI18n();
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token') || localStorage.getItem('customer:token');
    const origin = params.get('origin');
    if (!token)
        return null;
    localStorage.setItem('customer:token', token);
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    return (_jsx(UserContext.Provider, { value: {
            locale: currentLocale,
            token,
            origin,
        }, children: children }));
}
