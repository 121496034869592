import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { usePayment } from '../../contexts/PaymentContext';
import { useI18n } from '../../contexts/i18nContext';
import { useBooking } from '../../contexts/BookingContext';
import useRedirectToStripe from '../../hooks/useRedirectToStripe';
import CreditCard from './CreditCard';
import BankTransfer from './BankTransfer';
import OtherMethods from './OtherMethods';
import AppleAndGooglePay from './AppleAndGooglePay';
import FinishBooking from './FinishBooking';
export default function PaymentMethods() {
    const { booking } = useBooking();
    const { t } = useI18n();
    const { isFullPayment, firstDepartureInLessThan7Days, isInstallments } = usePayment();
    const { stripePromotedMethodUrl } = useRedirectToStripe();
    if (!booking)
        return null;
    if (isInstallments && stripePromotedMethodUrl) {
        return (_jsx(FinishBooking, { externalUrl: stripePromotedMethodUrl, text: t('continueToKlarna'), isValidForm: true }));
    }
    return (_jsxs("div", { className: "flex flex-col gap-1 mt-10", children: [_jsx("h2", { className: "text-lg font-bold text-black", children: t('choosePaymentMethod') }), isInstallments ? (_jsx(CreditCard, {})) : (_jsxs(_Fragment, { children: [_jsx(CreditCard, {}), !firstDepartureInLessThan7Days ? _jsx(BankTransfer, {}) : null, _jsx(OtherMethods, {}), isFullPayment ? _jsx(AppleAndGooglePay, {}) : null] }))] }));
}
