import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useEffect, useState } from 'react';
import { getBookingById } from '../api-requests/booking';
import Loading from '../components/Loading';
import Error404 from '../pages/Error/404';
const BookingContext = createContext({
    booking: null,
    refetch: () => Promise.resolve(null),
    changeCurrency: () => { },
});
export function useBooking() {
    return useContext(BookingContext);
}
export function BookingProvider({ children }) {
    const [leadCurrency, setCurrency] = useState('EUR');
    const [booking, setBooking] = useState(undefined);
    const params = new URLSearchParams(window.location.search);
    const bookingId = params.get('bookingId');
    useEffect(() => {
        if (!bookingId)
            return;
        getBookingById(bookingId)
            .then(booking => {
            setBooking(booking);
            if (booking.payment_plan) {
                const installmentsIds = booking.payment_plan.final.map(installment => installment._id);
                const hasPaidPayments = booking.payments.some(payment => installmentsIds.includes(payment.installment_id) && payment.status === 'succeeded');
                if (hasPaidPayments) {
                    return setCurrency(booking.payment_plan.original.displayCurrency);
                }
            }
            if (booking.lead.preferences.currency) {
                return setCurrency(booking.lead.preferences.currency);
            }
            if (booking.trip.pricing.currency) {
                return setCurrency(booking.trip.pricing.currency);
            }
        })
            .catch(() => {
            setBooking(null);
        });
    }, [bookingId]);
    const refetch = async () => {
        if (!bookingId)
            return null;
        const newBooking = await getBookingById(bookingId);
        setBooking(newBooking);
        return newBooking;
    };
    const changeCurrency = (currency) => {
        setCurrency(currency);
    };
    if (booking === undefined)
        return _jsx(Loading, {});
    if (booking === null)
        return _jsx(Error404, {});
    const [min, max] = booking.trip.duration;
    const isVariableDuration = min !== max;
    const bookingObj = {
        ...booking,
        isVariableDuration,
        leadCurrency,
    };
    return (_jsx(BookingContext.Provider, { value: { booking: bookingObj, refetch, changeCurrency }, children: children }));
}
