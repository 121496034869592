import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useBooking } from '../../../contexts/BookingContext';
import { useI18n } from '../../../contexts/i18nContext';
import { usePayment } from '../../../contexts/PaymentContext';
import useRedirectToStripe from '../../../hooks/useRedirectToStripe';
import formatMoney from '../../../lib/formatMoney';
import { PAYMENT_METHODS } from '../../../lib/payments/constant';
import Bancontact from '../../Icons/Bancontact';
import EPS from '../../Icons/EPS';
import GiroPay from '../../Icons/GiroPay';
import IDeal from '../../Icons/iDeal';
import Sepa from '../../Icons/Sepa';
import Sofort from '../../Icons/Sofort';
import CheckBox from '../CheckBox';
export default function OtherMethods() {
    const { t } = useI18n();
    const { booking } = useBooking();
    const { payWithOtherMethods, stripeUrl } = useRedirectToStripe();
    const { setPaymentMethod, basePriceWithCustomerFee, isFullPayment, isStripe, loadingPrices } = usePayment();
    if (!booking || !isFullPayment)
        return null;
    return stripeUrl ? (_jsx(CheckBox, { isActive: isStripe, setPaymentModality: () => {
            if (isStripe)
                return setPaymentMethod(null);
            payWithOtherMethods();
            return setPaymentMethod(PAYMENT_METHODS.STRIPE);
        }, label: _jsxs("div", { className: "w-full flex items-center gap-1", children: [t('otherPaymentMethods'), ' ', loadingPrices ? (_jsx("div", { className: "h-3 mt-1 bg-gray-200 rounded-full dark:bg-gray-200 w-1/6" })) : (_jsxs("span", { className: "text-sm font-extralight", children: ["(+", booking.leadCurrency, " 0.00)"] }))] }), children: _jsxs("div", { children: [_jsxs("div", { className: 'flex items-center gap-1', children: [t('youWillPayATotal'), ' ', loadingPrices ? (_jsxs(_Fragment, { children: [_jsx("div", { className: "h-3 mt-1 bg-gray-200 rounded-full dark:bg-gray-200 w-1/6" }), "."] })) : (_jsxs("strong", { children: [booking.leadCurrency, " ", formatMoney(basePriceWithCustomerFee, booking.leadCurrency)] })), ' '] }), _jsxs("div", { className: "flex items-center gap-2 mt-2", children: [_jsx(Sepa, {}), _jsx(Sofort, {}), _jsx(IDeal, {}), _jsx(Bancontact, {}), _jsx(GiroPay, {}), _jsx(EPS, {})] })] }) })) : null;
}
