import fetcher from './fetcher';
export default function updateLeadCurrency({ leadId, currency, }) {
    return fetcher({
        url: `/api/v3/lead/${leadId}/change-currency`,
        method: 'PATCH',
        data: { currency },
    });
}
export function updateLeadData({ leadId, data }) {
    return fetcher({
        url: `/api/v3/lead/${leadId}/add-values`,
        method: 'PATCH',
        data,
    });
}
