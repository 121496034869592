import { Fragment as _Fragment, jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import dayjs from 'dayjs';
import { useBooking } from '../../contexts/BookingContext';
import Item from './Item';
import { useI18n } from '../../contexts/i18nContext';
export default function Dates() {
    const { booking } = useBooking();
    const { t } = useI18n();
    if (!booking)
        return null;
    return (_jsx("div", { children: booking.departure_date ? (_jsx(Item, { label: "Departure date", value: _jsxs(_Fragment, { children: [dayjs.utc(booking.departure_date).startOf('day').format('MMM D, YY'), " -", ' ', dayjs
                        .utc(booking.departure_date)
                        .startOf('day')
                        .add(booking.duration - 1, 'days')
                        .format('MMM D, YY')] }) })) : (booking.availability.map(({ startDate, endDate }) => (_jsx(Item, { className: "text-right", label: t('availability'), value: _jsxs(_Fragment, { children: [dayjs.utc(startDate).startOf('day').format('MMM D, YY'), " -", ' ', dayjs.utc(endDate).startOf('day').format('MMM D, YY')] }) }, startDate)))) }));
}
